import React from "react";
import { FlexDiv } from "./Container";
import { DownloadApple } from "./DownloadApple";
import { DownloadAndroid } from "./DownloadAndroid";

interface DownloadBadgesProps {
  stack?: boolean;
  mobileStack?: boolean;
}

export const DownloadBadges: React.FC<DownloadBadgesProps> = ({ stack, mobileStack }) => {
  return (
    <FlexDiv
      align="center"
      direction={stack ? "column" : "row"}
      mobileDirection={mobileStack ? "column" : "row"}
      gap={16}
      mobileGap={8}
    >
      <DownloadApple />
      <DownloadAndroid />
    </FlexDiv>
  );
};
