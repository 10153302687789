import React from "react";
import { FlexDiv } from "../../ui";
import theme from "../../../util/theme";
import LeftLaurel from "../../../content/img/homepage/left-laurel.svg";
import AppleIcon from "../../../content/assets/icons/apple-logo-grey.png";
import GooglePlayIcon from "../../../content/assets/icons/google-play-logo-colored.png";
import styled from "styled-components";
import { mobileSize } from "../../../util/variables";

enum PLATFORM {
  Apple = "Apple",
  Google = "Google Play",
}

interface AppOfTheYearAwardsProps {
  light?: boolean;
}

interface AwardProps {
  type: PLATFORM;
  text: React.ReactNode;
  light?: boolean;
  alt?: string;
}

export const AppOfTheYearAwards: React.FC<AppOfTheYearAwardsProps> = ({
  light,
}) => {
  return (
    <AwardsContainer>
      <Award
        type={PLATFORM.Google}
        light={light}
        text={
          <AwardText>
            APP OF THE <br /> YEAR 2023
          </AwardText>
        }
      />
      <Award
        type={PLATFORM.Apple}
        light={light}
        text={
          <AwardText>
            APP OF THE <br /> DAY
          </AwardText>
        }
      />
      <Award
        type={PLATFORM.Apple}
        light={light}
        text={
          <AwardText>
            ESSENTIAL <br />
            EDUCATION APP
          </AwardText>
        }
        alt={"Essential education app"}
      />
    </AwardsContainer>
  );
};

const Award: React.FC<AwardProps> = ({ type, text, light, alt }) => {
  return (
    <FlexDiv direction="column" align="center">
      <FlexDiv align="center" gap={4}>
        <Laurel
          src={LeftLaurel}
          light={light}
          alt="left laurel"
          height={42}
          width={17.5}
        />
        <FlexDiv direction="column" align="center">
          <FlexDiv justify="center" align="center" mobileAlign="flex-end" gap={8} mobileGap={4}>
            <PlatformImg
              src={type === PLATFORM.Apple ? AppleIcon : GooglePlayIcon}
              width={type === PLATFORM.Apple ? 23 : 28}
              height={28}
              alt={alt}
              light={type === PLATFORM.Apple && light}
            />
          </FlexDiv>
        </FlexDiv>
        <Laurel
          src={LeftLaurel}
          light={light}
          alt="right laurel"
          height={42}
          width={17.5}
          style={{ transform: "scaleX(-1)" }}
        />
      </FlexDiv>
      {text}
    </FlexDiv>
  );
};

const AwardsContainer = styled.div`
  display: flex;
  gap: 40px;

  @media ${mobileSize} {
    width: 100%;
    gap: 16px;
    justify-content: space-between;
  }
`;

const PlatformImg = styled.img<{ light?: boolean }>`
  filter: ${props =>
    props.light ?
      "grayscale(100%) brightness(2.5)" :
      ""};
`;

const AwardText = styled.p`
  margin: 0;
  margin-top: 8px;

  font-size: 16px;
  font-family: ${theme.SECONDARY_FONT};
  font-weight: 500;
  line-height: 1.25;
  text-transform: uppercase;
  text-align: center;
  color: ${theme.BLACK_COLOR};
  @media ${mobileSize} {
    font-size: 14px;
  }
`;

const Laurel = styled.img<{ light?: boolean }>`
  filter: ${props => (props.light ? "brightness(1.05)" : "brightness(0.65)")};
  pointer-events: none;
`;
