import * as React from "react";
import styled from "styled-components";
import { AppTitle, AppText, Wrapper, AppButton } from "../../ui";
import theme from "../../../util/theme";
import { mobileSize, laptopSize } from "../../../util/variables";
import Img from "gatsby-image";
import { graphql, useStaticQuery } from "gatsby";
import { handleGetStarted } from "./HeroSection";

const StopForgettingSegment: React.FC = React.memo(() => {
  const data = useStaticQuery(graphql`
    query MindImageQuery {
      file(relativePath: { eq: "homepage/stop-forgetting.webp" }) {
        childImageSharp {
          fixed(quality: 100, height: 521, width: 644) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
        mobile: childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `);

  return (
    <SegmentContainer>
      <ImgDiv>
        <Img
          fixed={data.file.childImageSharp.fixed}
          style={{
            display: "inherit",
            minHeight: "100%",
            maxHeight: "100%",
            top: 0,
            bottom: 0,
          }}
          imgStyle={{
            objectFit: "contain",
            objectPosition: "right",
          }}
          alt="Stop Forgetting"
          loading="eager"
        />
      </ImgDiv>
      {/* <AbsImg src={stopForgettingImage} alt="Stop Forgetting" /> */}
      <MobileImageDiv>
        <Img fluid={data.file.mobile.fluid} alt="Stop forgetting" loading="eager" />
      </MobileImageDiv>
      <Wrapper>
        <AlignVerticallyDiv>
          <ContentDiv>
            <PageTitle>Stop forgetting what you read.</PageTitle>
            <PageText>
              Our visual approach is designed to help you commit key concepts to memory. Plus, we
              make it easy to save and review the ideas that matter most to you.
            </PageText>
            <AppButton onClick={handleGetStarted}>Get Started</AppButton>
            {/* <DownloadBadges /> */}
          </ContentDiv>
        </AlignVerticallyDiv>
      </Wrapper>
    </SegmentContainer>
  );
});

const MobileImageDiv = styled.div`
  display: none;
  @media ${mobileSize} {
    display: block;
    position: relative;
    width: 100vw;
    max-height: 100vw;
    margin-bottom: 30px;
  }
`;

const PageText = styled(AppText as any)`
  max-width: 521px;
  z-index: 4;
  @media ${laptopSize} {
    max-width: 50vw;
  }
  @media ${mobileSize} {
    max-width: 100%;
    z-index: 1;
  }
`;
const PageTitle = styled(AppTitle as any)`
  max-width: 521px;
  text-align: left;
  z-index: 4;
  @media ${laptopSize} {
    max-width: 50vw;
  }
  @media ${mobileSize} {
    margin-top: 15px;
    max-width: 100%;
    z-index: 1;
  }
`;

const ImgDiv = styled.div`
  position: absolute;
  top: 0;
  right: 32px;
  bottom: 0;
  min-height: 100%;
  z-index: 1;
  @media ${mobileSize} {
    display: none;
  }
  @media ${laptopSize} {
    top: 0;
    bottom: 0;
    right: -20%;
    max-height: 50vw;
  }
`;

const ContentDiv = styled.div`
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const AlignVerticallyDiv = styled.div`
  height: 521px;
  display: flex;
  align-items: center;
  z-index: 4;
  @media ${mobileSize} {
    height: fit-content;
    margin-bottom: 80px;
  }
`;

const DownloadButton = styled.button`
  font-family: ${theme.PRIMARY_FONT};
  background: linear-gradient(
      90deg,
      rgba(115, 123, 253, 0.75) 1.68%,
      rgba(50, 123, 210, 0.75) 116.81%
    ),
    #1e85e2;
  border-radius: 32px;
  font-size: 17px;
  line-height: 20px;
  width: 225px;
  height: 52px;
  color: ${theme.WHITE_COLOR};
  @media ${mobileSize} {
    margin-top: 10px;
    margin-bottom: 60px;
    width: 100%;
  }
`;

const SegmentContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 100vw;
  min-height: 521px;
  max-height: 521px;
  background-color: ${theme.GREY_COLOR};
  /* z-index: -2; */
  @media ${mobileSize} {
    display: flex;
    flex-direction: column;
    min-height: fit-content;
    max-height: fit-content;
    padding-top: 36px;
  }
`;

export { StopForgettingSegment };
