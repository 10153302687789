import * as React from "react";
import styled from "styled-components";
import { AppTitle, AppText } from "../../ui";
import theme from "../../../util/theme";
import { mobileSize } from "../../../util/variables";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import { DownloadBadges } from "../../ui/DownloadBadges";

const InsightsSegment: React.FC = React.memo(() => {
  const data = useStaticQuery(graphql`
    query WhyWeSleepImageQuery {
      file(relativePath: { eq: "homepage/insights-display.webp" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 2880, maxHeight: 1051) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
        mobile: childImageSharp {
          fluid(quality: 100, maxHeight: 623, maxWidth: 600) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `);

  return (
    <SegmentContainer>
      <CenterDiv>
        <InsightsAppTitle>Insights from the world’s greatest thinkers.</InsightsAppTitle>
        <InsightsAppText>
          Key insights into essential topics, from the world’s most-respected experts, courses, and
          books.
        </InsightsAppText>
      </CenterDiv>
      <StretchImg>
        <DesktopDiv>
          <Img
            fluid={data.file.childImageSharp.fluid}
            style={{ minHeight: "400px" }}
            imgStyle={{
              objectFit: "cover",
            }}
            alt="Books: Attached, Ikgai, Lifespan, Think Again, The 5 second rule, etc."
            loading="eager"
          />
        </DesktopDiv>
        <MobileDiv>
          <Img
            fluid={data.file.mobile.fluid}
            imgStyle={{
              objectFit: "cover",
            }}
            alt="Books: Attached, Ikgai, Lifespan, Think Again, The 5 second rule, etc."
            loading="eager"
          />
        </MobileDiv>
      </StretchImg>
      <CenterDiv>
        {/* <InsightsAppTitle>Try Imprint for free</InsightsAppTitle> */}
        <DownloadBadges />
      </CenterDiv>
    </SegmentContainer>
  );
});

const CenterDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media ${mobileSize} {
    padding-bottom: 28px;
  }
`;

const InsightsAppTitle = styled(AppTitle as any)`
  text-align: center;
  max-width: 792px;
  margin-bottom: 40px;
  margin-top: 32px;
  @media ${mobileSize} {
    max-width: 90%;
    margin-bottom: 32px;
    text-align: left;
  }
`;

const InsightsAppText = styled(AppText as any)`
  text-align: center;
  max-width: 450px;
  @media ${mobileSize} {
    max-width: 90%;
    text-align: left;
  }
`;

const StretchImg = styled.div`
  width: 100%;
`;

const SegmentContainer = styled.div`
  width: 100%;
  background-color: ${theme.WHITE_COLOR};
  padding: 72px 0px;
  @media ${mobileSize} {
    padding: 30px 0px;
  }
`;

const DesktopDiv = styled.div`
  @media ${mobileSize} {
    display: none;
  }
`;

const MobileDiv = styled.div`
  display: none;
  @media ${mobileSize} {
    display: block;
  }
`;

export { InsightsSegment };
